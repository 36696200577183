@import '../../../styles/var';

.wrap {
    position: relative;

    .title {}

    .text {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 140px;
        margin: 20px 0 60px;

        @media(max-width: $md3+px) {
            gap: 100px;
        }

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        @media(max-width: $md6+px) {
            margin-bottom: 40px;
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 20px;

        @media(max-width: $md4+px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(max-width: $md5+px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(4, 1fr);
        }

        .card {
            position: relative;
            border-radius: 10px;
            border: 1px solid #262626;
            background: #191919;
            padding: 40px;

            @media(max-width: $md3+px) {
                padding: 40px 30px;
            }

            @media(max-width: $md4+px) {
                grid-column: initial !important;
                grid-row: initial !important;
            }

            @media(max-width: $md6+px) {
                padding: 30px 18px;
            }

            &Title {
                position: relative;
                z-index: 5;
            }

            &Text {
                position: relative;
                z-index: 5;
                margin-top: 14px;
                max-width: 530px;
            }

            &Image {
                position: absolute;
                z-index: 2;

                @media(max-width: $md4+px) {
                    width: 100%!important;
                    opacity: 0.5;
                }

                @media(max-width: $md5+px) {
                    opacity: 0.2;
                }

                @media(max-width: $md6+px) {
                    opacity: 0.5;
                }
            }

            &:nth-child(1) {
                grid-column: 1/1;
                grid-row: 1/1;
            }

            &:nth-child(2) {
                grid-column: 2/2;
                grid-row: 1/1;
            }

            &:nth-child(3) {
                grid-column: 3/3;
                grid-row: 1/3;

                .cardImage {
                    bottom: 0;
                    left: 0;
                    width: 100%;
                }
            }

            &:nth-child(4) {
                grid-column: 1/3;
                grid-row: 2/2;

                .cardImage {
                    width: 567px;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }
}