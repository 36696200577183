@import '../../../styles/var';

.container {
    position: relative;

    .image {
        position: absolute;
        z-index: 2;
        top: 60px;
        right: 0;
        width: 800px;

        @media(max-width: $md3+px) {
            width: 600px;
            top: 150px;
        }

        @media(max-width: $md4+px) {
            position: relative;
            top: unset;
            display: flex;
            margin: 40px auto 0;
            width: 100%;
            max-width: 500px;
        }
    }
}

.wrap {
    position: relative;
    padding-top: 180px;

    @media(max-width: $md1+px) {
        padding-top: 160px;
    }

    @media(max-width: $md3+px) {
        padding-top: 140px;
    }

    @media (max-width: $md6+px) {
        padding-top: 120px;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {

            @media(max-width: $md6+px) {
                font-size: 24px;
            }
            span {
                font-family: $manrope;
                font-size: 64px;
                font-weight: 600;
                line-height: 120%;
                text-transform: uppercase;
                background: linear-gradient(90deg, #9869DA 0%, #F8AD79 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media(max-width: $md4+px) {
                    font-size: 50px;
                }

                @media(max-width: $md6+px) {
                    font-size: 32px;
                }
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 40px 0;
            max-width: 585px;

            @media(max-width: $md4+px) {
                max-width: 100%;
            }

            @media(max-width: $md6+px) {
                gap: 15px;
                margin: 20px 0;
            }
        }

        .subtitle {
            color: #FFF;
        }
    }
}