@import "../../../styles/var";

.footerWrap {
    padding-bottom: 50px;
    color: #fff;

    @media(max-width: $md6+px) {
        padding-bottom: 30px;
    }
}

.footer {
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    gap: 60px;

    @media(max-width: $md3+px) {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .logo {
        width: 100px;

        @media (max-width: $md3+px) {
            width: 100px;
        }

        @media(max-width: $md6+px) {
            width: 80px;
        }
    }

    .contacts,
    .docs {
        display: flex;
        flex-direction: column;
        gap: 10px;

        a {
            text-decoration: none;
            color: #fff;

            &:hover {
                color: rgba(255, 255, 255, 0.70);
            }
        }
    }

    .about {
        .block {
            display: flex;
            flex-direction: column;
            gap: 10px;
            font-family: $roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
        }

        .rights {
            text-align: right;
            margin-top: 20px;
            font-family: $roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}