@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        text-align: center;

        @media(max-width: $md4+px) {
            text-align: left;
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 80px;
        gap: 70px 100px;
        
        @media(max-width: $md3+px) {
            gap: 70px;
        }

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 30px;
            margin-top: 60px;
        }

        @media(max-width: $md6+px) {
            margin-top: 40px;
        }

        .card {
            display: grid;
            grid-template-columns: 82px 1fr;
            gap: 60px;

            @media(max-width: $md3+px) {
                grid-template-columns: 60px 1fr;
                gap: 40px;
            }

            @media(max-width: $md6+px) {
                grid-template-columns: 52px 1fr;
                gap: 20px;
            }

            .icon {
                width: 100%;
            }
            
            .content {

                .subtitle {

                }

                .text {
                    margin-top: 14px;
                    color: rgba(255, 255, 255, 0.70);

                    @media(max-width: $md6+px) {
                        gap: 12px;
                    }
                }
            }
        }
    }
}