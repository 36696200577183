@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        max-width: 520px;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 20px;
        margin-top: 60px;

        @media(max-width: $md4+px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(max-width: $md5+px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(6, auto);
        }

        @media(max-width: $md6+px) {
            margin-top: 40px;
        }

        .card {
            position: relative;
            overflow: hidden;
            border-radius: 10px;
            border: 1px solid #262626;
            background: #191919;
            padding: 40px;

            @media(max-width: $md3+px) {
                padding: 40px 30px;
            }

            @media(max-width: $md4+px) {
                grid-column: initial !important;
                grid-row: initial !important;
            }

            @media(max-width: $md6+px) {
                padding: 30px 18px;
            }

            &Title {
                position: relative;
                z-index: 5;
                max-width: 450px;
            }

            &Text {
                position: relative;
                z-index: 5;
                margin-top: 14px;
                max-width: 400px;
            }

            &Image {
                position: absolute;
                z-index: 2;

                @media(max-width: $md4+px) {
                    display: none;
                }
            }

            &:nth-child(1) {
                grid-column: 1 / span 2;
                grid-row: 1 / span 1;
            }

            &:nth-child(2) {
                grid-column: 3 / span 2;
                grid-row: 1 / span 1;
            }

            &:nth-child(3) {
                grid-column: 5 / span 2;
                grid-row: 1 / span 2;

                .cardImage {
                    width: 358px;
                    right: 0;
                    bottom: 0;
                }
            }

            &:nth-child(4) {
                grid-column: 1 / span 4;
                grid-row: 2 / span 1;

                .cardImage {
                    width: 377px;
                    right: 40px;
                    bottom: 35px;
                }
            }

            &:nth-child(5) {
                grid-column: 1 / span 3;
                grid-row: 3 / span 1;
            }

            &:nth-child(6) {
                grid-column: 4 / span 3;
                grid-row: 3 / span 1;

                .cardImage {
                    width: 392px;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }
}