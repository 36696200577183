@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        max-width: 400px;
    }

    .swiperWrap {
        margin-top: 60px;

        @media (max-width: $md6+px) {
            margin-top: 40px;
        }

        .card {
            border-radius: 10px;
            border: 1px solid #262626;
            background: #191919;
            padding: 24px;
            height: 100%;

            &Head {
                display: flex;
                align-items: center;
                gap: 20px;

                .icon {
                    width: 40px;
                }

                &Content {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    @media (max-width: $md3+px) {
                        flex-direction: column;
                        align-items: unset;
                        gap: 10px;
                    }

                    .cardHeadTitle {}

                    .stars {
                        width: 94px;

                        @media (max-width: $md3+px) {
                            width: 80px;
                        }
                    }
                }
            }

            &Text {
                margin-top: 20px;
            }
        }
    }

    .nav {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 4;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
        margin-top: 20px;

        @media (max-width: 950px) {
            justify-content: space-between;
            position: relative;
            margin-top: 20px;
        }

        &Btn {
            width: 88px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #64ABFF;
            border-radius: 70px;
            cursor: pointer;
            border: 1px solid #64ABFF;
            transition: opacity 0.3s, border-color 0.3s, background 0.3s;

            @media(max-width: $md4+px) {
                width: 70px;
                height: 46px;
            }

            &.left {
                transform: rotate(180deg);
            }

            &.inactive {
                opacity: 1 !important;
                border-color: #313131;
                background: transparent;

                .arrow path {
                    stroke: #fff;
                }
            }

            .arrow path {
                transition: all .3s;
            }

            &:hover {
                opacity: 0.8;
            }
        }

        &Num {
            text-align: center;
        }
    }
}

:global(.aboutSeeWhatSwiper) {
    overflow: visible !important;
}

:global(.aboutSeeWhatSwiperSlide) {
    height: auto !important;
}