@import '../../../styles/var';

.container {
    padding-top: 70px;

    @media(max-width: $md3+px) {
        padding-top: 0;
    }
}

.wrap {
    position: relative;
    border-radius: 10px;
    background: linear-gradient(90deg, #9869DA 0%, #F8AD79 100%);
    padding: 40px 80px;
    color: #000;

    @media(max-width: $md3+px) {
        padding: 40px;
    }

    @media(max-width: $md4+px) {
        padding: 40px 60px 0;
    }

    @media(max-width: 600px) {
        padding: 40px 40px 0;
    }

    @media(max-width: $md6+px) {
        padding: 30px 18px 0;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {
            font-weight: 500;
            color: #000;
        }

        .subtitle {
            margin: 5px 0 20px;
            max-width: 600px;
            color: #000;
        }

        .text {
            max-width: 512px;
            
            @media(max-width: $md4+px) {
                max-width: 100%;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 0;
        width: 572px;

        @media(max-width: $md3+px) {
            width: 400px;
        }

        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            margin: 40px auto 0;
            width: 100%;
            max-width: 400px;
        }
    }
}