@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 20px;

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .block {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid #262626;
        background: #191919;
        padding: 40px 40px 120px;

        @media(max-width: $md6+px) {
            padding: 30px 18px 100px;
        }

        &:nth-child(1) {
            .image {
                width: 538px;

                @media(max-width: $md3+px) {
                    width: 400px;
                }

                @media(max-width: $md6+px) {
                    width: 300px;
                }
            }
        }

        &:nth-child(2) {
            .image {
                width: 265px;

                @media(max-width: $md3+px) {
                    width: 200px;
                }

                @media(max-width: $md6+px) {
                    width: 120px;
                }
            }
        }

        .title, .list {
            position: relative;
            z-index: 5;
        }

        .list {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            gap: 14px;

            @media(max-width: $md6+px) {
                margin-top: 30px;
            }

            .item {
                position: relative;
                padding-left: 26px;

                @media(max-width: $md6+px) {
                    padding-left: 22px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 14px;
                    height: 14px;
                    background-image: url('../../../assets/img/list_check.svg');
                    background-repeat: no-repeat;
                    background-position: center top;
                    background-size: cover;

                    @media(max-width: $md6+px) {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }

        .image {
            position: absolute;
            z-index: 2;
            bottom: 0;
            right: 0;
        }
    }
}