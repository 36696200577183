@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
    gap: 60px;

    @media(max-width: $md4+px) {
        display: flex;
        align-items: unset;
        flex-direction: column;
    }

    @media(max-width: $md6+px) {
        gap: 50px;
    }

    .content {
        position: relative;
        z-index: 5;
        min-width: 512px;

        @media(max-width: $md4+px) {
            min-width: unset;
        }
        
        .text {
            margin-top: 40px;
            max-width: 512px;

            @media(max-width: $md4+px) {
                max-width: 100%;
            }
        }
    }

    .image {
        max-width: 497px;
        width: 100%;
        
        @media(max-width: $md4+px) {
            display: flex;
            margin: 0 auto;
            max-width: 400px;
        }
    }
}