@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 497px calc(100% - 497px - 120px);
    gap: 120px;
    align-items: flex-end;

    @media(max-width: $md3+px) {
        grid-template-columns: 400px calc(100% - 400px - 100px);
        gap: 80px;
    }

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column-reverse;
        align-items: unset;
        gap: 60px;
    }

    @media(max-width: $md6+px) {
        gap: 50px;
    }

    .image {

        @media(max-width: $md4+px) {
            width: 100%;
            max-width: 400px;
            display: flex;
            margin: 0 auto;
        }

        img {
            width: 100%;
        }
    }

    .content {

        .swiperWrap {

            .card {
                height: 100%;

                &Text {
                    margin-top: 40px;
                    color: rgba(255, 255, 255, 0.70);
                }
            }
        }

        .nav {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-top: 30px;
            
            @media(max-width: $md6+px) {
                margin-top: 20px;
                justify-content: space-between;
            }

            &Btn {
                width: 88px;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #64ABFF;
                border-radius: 70px;
                cursor: pointer;
                border: 1px solid #64ABFF;
                transition: opacity 0.3s, border-color 0.3s, background 0.3s;

                @media(max-width: $md4+px) {
                    width: 70px;
                    height: 46px;
                }

                &.left {
                    transform: rotate(180deg);
                }

                &.inactive {
                    opacity: 1 !important;
                    border-color: #313131;
                    background: transparent;

                    .arrow path {
                        stroke: #fff;
                    }
                }

                .arrow path {
                    transition: all .3s;
                }

                &:hover {
                    opacity: 0.8;
                }
            }

            &Num {
                text-align: center;
            }
        }
    }
}

:global(.toolsUnderbannerSwiper) {
    overflow: hidden !important;

    @media(max-width: $md6+px) {
        overflow: visible !important;
    }
}

:global(.toolsUnderbannerSwiperSlide) {
    height: auto !important;
}