@import '../../../styles/var';

.input {
    width: 100%;
    height: 46px;
    padding: 0 30px;
    border-radius: 23px;
    background: rgba(255, 255, 255, 0.30);

    color: #000;
    font-family: $roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;

    @media(max-width: $md6+px) {
        font-size: 14px;
        padding: 0 20px;
    }

    &::placeholder {
        color: rgba(0, 0, 0, 0.70);
    }

    &:focus {
        background: rgba(255, 255, 255, 0.40);
    }

    &.error {
        border-color: red;
    }
}

.phone {
    padding: 0;
    border: none;
    text-align: left !important;

    &.containerClass {}

    .inputClass {
        width: 100% !important;
        height: 46px !important;
        border: none !important;
        border-radius: 23px !important;
        background: rgba(255, 255, 255, 0.30)!important;

        color: #000 !important;
        font-family: $roboto;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 150% !important;

        @media(max-width: $md6+px) {
            font-size: 14px !important;
        }

        &.error {
            border-color: red !important;
        }

        &:focus {
            background: rgba(255, 255, 255, 0.40) !important;
        }
    }

    .buttonClass {
        background: transparent !important;
        border: none !important;

        &:hover {
            background: transparent;
        }

        :global(.selected-flag) {
            transition: all .1s;
            border-radius: 10px 0 0 10px !important;

            &:hover,
            &.open {
                background: transparent !important;
            }

            &:global(.open) {
                background: transparent !important;
            }
        }
    }

    .dropdownClass {
        color: #000 !important;
    }

    .searchClass {
        width: 100% !important;
        padding: 5px !important;

        input {
            border: none !important;
            width: 100%;
            margin-left: 0 !important;
        }
    }
}

.textarea {
    width: 100%;
    height: 100%;
    max-height: 400px;
    padding: 13px 30px;
    border-radius: 23px;
    background: rgba(255, 255, 255, 0.30);
    resize: none;

    color: #000;
    font-family: $roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;

    @media(max-width: 700px) {
        min-height: 100px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
        padding: 13px 20px;
    }

    &::placeholder {
        color: rgba(0, 0, 0, 0.70);
    }

    &:focus {
        background: rgba(255, 255, 255, 0.40);
    }

    &.error {
        border-color: red;
    }
}