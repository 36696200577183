@import '../../../styles/var';

.wrap {
    position: relative;

    .sphere_01 {
        position: absolute;
        z-index: 2;
        left: 0;
        bottom: 60px;
    }

    .sphere_02 {
        position: absolute;
        z-index: 2;
        top: 200px;
        right: 250px;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 20px;

        @media(max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .nav {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
        justify-content: space-between;

        &Btn {
            width: 88px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #64ABFF;
            border-radius: 70px;
            cursor: pointer;
            border: 1px solid #64ABFF;
            transition: opacity 0.3s, border-color 0.3s, background 0.3s;

            @media(max-width: $md4+px) {
                width: 70px;
                height: 46px;
            }

            &.left {
                transform: rotate(180deg);
            }

            &.inactive {
                opacity: 1 !important;
                border-color: #313131;
                background: transparent;

                .arrow path {
                    stroke: #fff;
                }
            }

            .arrow path {
                transition: all .3s;
            }

            &:hover {
                opacity: 0.8;
            }
        }

        &Num {
            text-align: center;
        }
    }
}

.card {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    border-radius: 10px;
    padding: 40px 40px 20px;
    height: 100%;

    @media(max-width: $md3+px) {
        grid-column: initial !important;
        grid-row: initial !important;
    }

    @media(max-width: $md6+px) {
        padding: 30px 18px;
    }

    .content {

        .title {
            font-weight: 600;
        }

        .text {
            margin: 20px 0;

            @media(max-width: $md6+px) {
                margin: 14px 0 20px;
            }
        }

        .rows {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .row {
                display: grid;
                justify-content: space-between;
                grid-template-columns: 63% 34%;

                &.rowLine {
                    display: block;
                }

                &Title {
                    font-weight: 500;
                }

                &Text {}
            }
        }
    }

    .btn {}

    &_01 {
        grid-column: 1/1;
        grid-row: 1/1;
        background: #191919;

        .title {
            display: inline-flex;
            background: linear-gradient(90deg, #9869DA 0%, #F8AD79 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .text {
            color: rgba(255, 255, 255, 0.70);

            @media(max-width: $md4+px) {
                max-width: 400px;
            }
        }

        .rows {
            color: #FFF;

            .rowText {
                svg g path {
                    fill: #fff;
                }
            }
        }
    }

    &_02 {
        grid-column: 2/2;
        grid-row: 1/1;
        background: #FFF;

        .title {
            color: #64ABFF;
        }

        .text {
            color: rgba(0, 0, 0, 0.70);
        }

        .rows {
            color: #000;
        }
    }

    &_03 {
        grid-column: 2/2;
        grid-row: 2/2;
        background: #64ABFF;

        .title {
            color: #191919;
        }

        .text {
            color: rgba(0, 0, 0, 0.70);
        }

        .rows {
            color: #000;
        }
    }

    &_04 {
        grid-column: 3/3;
        grid-row: 2/2;
        background: linear-gradient(90deg, #9869DA 0%, #F8AD79 100%);

        .title {
            color: #191919;
        }

        .text {
            color: rgba(0, 0, 0, 0.70);
        }

        .rows {
            color: #000;
        }
    }
}

:global(.accountsSwiper) {
    overflow: visible !important;
}

:global(.accountsSwiperSlide) {
    height: auto !important;
}