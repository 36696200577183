@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 536px 521px;
    justify-content: space-between;
    align-items: center;
    gap: 100px;

    @media(max-width: $md3+px) {
        grid-template-columns: 40% 54%;
        gap: 0;
    }

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column-reverse;
        align-items: unset;
        gap: 50px;
    }
    
    @media(max-width: $md6+px) {
        gap: 40px;
    }

    .image {
        @media(max-width: $md4+px) {
            width: 100%;
            max-width: 400px;
            display: flex;
            margin: 0 auto;
        }

        img {
            width: 100%;
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 14px;

        .item {
            position: relative;
            padding-left: 26px;

            @media(max-width: $md6+px) {
                padding-left: 22px;
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 14px;
                height: 14px;
                background-image: url('../../../assets/img/list_check.svg');
                background-repeat: no-repeat;
                background-position: center top;
                background-size: cover;

                @media(max-width: $md6+px) {
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }
}