@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        margin-bottom: 80px;

        @media(max-width: $md6+px) {
            margin-bottom: 60px;
        }

        span {
            background: linear-gradient(90deg, #9B6BD8 0%, #F6AC7C 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .buttons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        gap: 20px;

        @media(max-width: $md5+px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(3, 1fr);
        }

        @media(max-width: $md6+px) {
            gap: 15px;
        }

        .button {
            display: grid;
            grid-template-columns: 1fr 24px;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            border-radius: 70px;
            border: 1px solid #313131;
            transition: all .2s;
            color: #fff;
            min-height: 56px;
            padding: 5px 50px;
            cursor: pointer;

            @media(max-width: $md3+px) {
                padding: 5px 24px;
            }

            * {
                transition: all .2s;
            }

            .arrow {
                width: 24px;
                height: 24px;
            }

            &.active {
                background: #191919;
                color: #64ABFF;

                .arrow {
                    transform: rotate(90deg);

                    rect {
                        fill: #64ABFF;
                    }

                    path {
                        stroke: #000;
                    }
                }
            }
        }
    }

    .tab {
        margin: 60px 0;

        @media(max-width: $md6+px) {
            margin: 50px 0;
        }
    }
}