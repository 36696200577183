@import '../../../styles/var';

.wrap {
    position: relative;
    padding-top: 40px;

    @media(max-width: $md3+px) {
        padding-top: 0;
    }

    .content {
        position: relative;
        z-index: 5;

        .text {
            max-width: 551px;
            margin: 20px 0 40px;
        }

        .list {
            display: flex;
            flex-direction: column;
            gap: 14px;
            max-width: 500px;

            @media(max-width: $md4+px) {
                max-width: 100%;
            }

            .item {
                position: relative;
                padding-left: 26px;

                @media(max-width: $md6+px) {
                    padding-left: 22px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 14px;
                    height: 14px;
                    background-image: url('../../../assets/img/list_check.svg');
                    background-repeat: no-repeat;
                    background-position: center top;
                    background-size: cover;

                    @media(max-width: $md6+px) {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        bottom: -20px;
        right: 0;
        width: 497px;

        @media(max-width: $md3+px) {
            width: 350px;
            bottom: 0;
        }

        @media(max-width: $md4+px) {
            position: relative;
            width: 100%;
            max-width: 350px;
            display: flex;
            margin: 40px auto 0;
        }
    }
}