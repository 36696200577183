@import '../../../styles/var';

.container {
    background: #191919;
}

.wrap {
    position: relative;
    padding-top: 45px;
    height: 100vh;
    min-height: 800px;
    max-height: 1100px;
    display: flex;
    align-items: center;

    @media(max-width: $md3+px) {
        display: block;
        padding-top: 140px;
        height: auto;
        min-height: unset;
        max-height: unset;
    }

    @media(max-width: $md6+px) {
        padding-top: 120px;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {}

        .subtitle {}

        .text_01 {
            margin: 40px 0 20px;
            max-width: 585px;

            @media(max-width: $md3+px) {
                max-width: 100%;
            }
        }

        .text_02 {
            max-width: 560px;

            @media(max-width: $md3+px) {
                max-width: 100%;
            }
        }

        .btn {}
    }

    .images {
        display: flex;
        position: absolute;
        z-index: 4;
        width: 675px;
        right: -125px;
        top: calc(50% + 50px);
        transform: translateY(-50%);

        @media(max-width: $md1+px) {
            transform: translateY(-50%) scale(0.85);
        }

        @media(max-width: $md3+px) {
            transform: none;
            top: unset;
            right: unset;
            position: relative;
            display: flex;
            margin: 35px auto 0;
            width: 550px;
        }

        @media(max-width: $md6+px) {
            width: 330px;
        }

        .image {
            position: relative;
            z-index: 3;
            width: 100%;
        }

        .circle_01,
        .circle_02 {
            position: absolute;
            z-index: 2;
        }

        .circle_01 {
            width: 193px;
            left: -3px;
            top: 160px;
            
            @media(max-width: $md3+px) {
                width: 130px;
            }

            @media(max-width: $md6+px) {
                width: 100px;
                left: -5px;
                top: 76px;
            }
        }

        .circle_02 {
            width: 275px;
            top: -15px;
            right: 93px;

            @media(max-width: $md3+px) {
                width: 200px;
            }
            
            @media(max-width: $md6+px) {
                width: 140px;
                top: -7px;
                right: 50px;
            }
        }

        .lines {
            position: absolute;
            z-index: 4;
            bottom: 18px;
            left: -160px;
            width: 639px;

            @media(max-width: $md3+px) {
                width: 450px;
                left: -80px;
            }

            @media(max-width: $md6+px) {
                width: 331px;
                left: -83px;
                bottom: 6px;
            }
        }
    }
}