@import '../../../styles/var';

.container {
    background: #191919;
}

.wrap {
    padding-top: 240px;
    position: relative;
    height: 100vh;
    min-height: 800px;
    max-height: 1000px;

    @media(max-width: $md1+px) {
        padding-top: 160px;
        min-height: 700px;
        max-height: 900px;
    }

    @media(max-width: $md3+px) {
        padding-top: 140px;
        height: auto;
        min-height: unset;
        max-height: unset;
    }

    @media(max-width: $md6+px) {
        padding-top: 120px;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {
            span {
                font-family: $manrope;
                font-size: 64px;
                font-weight: 600;
                line-height: 120%;
                text-transform: uppercase;
                background: linear-gradient(90deg, #9869DA 0%, #F8AD79 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media(max-width: $md4+px) {
                    font-size: 50px;
                }

                @media(max-width: $md6+px) {
                    font-size: 40px;
                }
            }
        }

        .text {
            max-width: 573px;
            margin-top: 40px;

            @media(max-width: $md3+px) {
                max-width: 100%;
            }

            @media(max-width: $md6+px) {
                margin-top: 20px;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 0;
        width: 568px;

        @media(max-width: $md1+px) {
            width: 500px;
        }

        @media(max-width: $md3+px) {
            position: relative;
            display: flex;
            margin: 50px auto 0;
        }

        @media(max-width: $md4+px) {
            width: 100%;
            max-width: 400px;
        }
    }
}