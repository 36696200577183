@import '../../../../styles/var';

.wrap {
    position: relative;

    .head {

        &Flex {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;

            @media(max-width: $md3+px) {
                display: grid;
                grid-template-columns: 1fr 400px;
                gap: 40px;
            }

            @media(max-width: $md4+px) {
                display: flex;
                flex-direction: column;
                align-items: unset;
                justify-content: unset;
                gap: 40px;
            }
        }

        .content {

            .title {}

            .subtitle {
                margin-top: 12px;
                max-width: 512px;
            }

            .text {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                max-width: 512px;

                @media(max-width: $md4+px) {
                    max-width: 100%;
                }
            }

            .list {
                margin-top: 40px;
                display: flex;
                flex-direction: column;
                gap: 14px;

                &Grid {
                    display: grid;
                    grid-template-columns: repeat(2, minmax(300px, 512px));
                    justify-content: space-between;
                    gap: 14px 60px;

                    @media(max-width: 900px) {
                        display: flex;
                        flex-direction: column;
                    }

                    .flex {
                        display: flex;
                        flex-direction: column;
                        gap: 14px;
                    }
                }

                .item {
                    position: relative;
                    padding-left: 26px;
                    max-width: 512px;

                    @media(max-width: $md6+px) {
                        padding-left: 22px;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 14px;
                        height: 14px;
                        background-image: url('../../../../assets/img/list_check.svg');
                        background-repeat: no-repeat;
                        background-position: center top;
                        background-size: cover;

                        @media(max-width: $md6+px) {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }
        }

        .image {
            @media(max-width: $md3+px) {
                width: 100%;

                img {
                    width: 100%;
                }
            }

            @media(max-width: $md4+px) {
                max-width: 400px;
                display: flex;
                margin: 0 auto;
            }
        }
    }

    .cardsWrap {
        margin-top: 80px;

        .title {
            text-align: center;
        }

        .cards {
            margin-top: 60px;
            display: grid;
            grid-template-columns: repeat(2, 500px);
            grid-template-rows: repeat(2, 217px);
            gap: 40px 20px;
            justify-content: center;

            @media(max-width: $md3+px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media(max-width: $md4+px) {
                grid-template-rows: repeat(2, 1fr);
            }

            @media(max-width: 900px) {
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: repeat(4, auto);
            }

            @media(max-width: $md6+px) {
                margin-top: 40px;
                gap: 20px;
            }

            .card {
                position: relative;
                display: grid;
                grid-template-columns: 126px 1fr;
                align-items: center;
                gap: 40px;
                padding: 5px 60px 5px 0;

                @media(max-width: $md3+px) {
                    transform: none !important;
                    padding-right: 40px;
                }

                @media(max-width: $md4+px) {
                    grid-template-columns: 100px 1fr;
                    padding: 30px 30px 30px 0;
                }

                @media(max-width: 900px) {
                    padding-right: 60px;
                }

                @media(max-width: $md6+px) {
                    display: flex;
                    flex-direction: column;
                    padding: 0 35px 35px;
                    gap: 30px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    z-index: 2;
                    right: 0;
                    top: 0;
                    width: calc(100% - 63px);
                    height: 100%;
                    border-radius: 10px;
                    border: 1px solid #262626;
                    background: #191919;

                    @media(max-width: $md4+px) {
                        width: calc(100% - 50px);
                    }

                    @media(max-width: $md6+px) {
                        width: 100%;
                        height: calc(100% - 45px);
                        top: unset;
                        bottom: 0;
                    }
                }

                .num {
                    position: relative;
                    z-index: 5;
                    width: 126px;
                    height: 126px;
                    background-image: url('../../../../assets/img/PartnershipPage/banner_circle.png');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: #000;
                    font-weight: 500;

                    @media(max-width: $md4+px) {
                        width: 100px;
                        height: 100px;
                    }

                    @media(max-width: $md6+px) {
                        width: 90px;
                        height: 90px;
                    }
                }

                &Text {
                    position: relative;
                    z-index: 5;
                    color: rgba(255, 255, 255, 0.70);

                    span {
                        color: #FFF;
                        font-weight: 500;
                    }
                }

                &:nth-child(1),
                &:nth-child(2) {
                    transform: translateX(-90px);
                }

                &:nth-child(3),
                &:nth-child(4) {
                    transform: translateX(90px);
                }
            }
        }
    }

    .block {
        margin-top: 80px;

        .title {
            max-width: 500px;
        }

        .cards {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 60px;
            gap: 70px 100px;

            @media(max-width: $md3+px) {
                gap: 70px;
            }

            @media(max-width: $md4+px) {
                display: flex;
                flex-direction: column;
                gap: 30px;
            }

            @media(max-width: $md6+px) {
                margin-top: 40px;
            }

            .card {
                display: grid;
                grid-template-columns: 82px 1fr;
                gap: 60px;

                @media(max-width: $md3+px) {
                    grid-template-columns: 60px 1fr;
                    gap: 40px;
                }

                @media(max-width: $md6+px) {
                    grid-template-columns: 52px 1fr;
                    gap: 20px;
                }

                .icon {
                    width: 100%;
                }

                .content {

                    .subtitle {}

                    .text {
                        margin-top: 14px;
                        color: rgba(255, 255, 255, 0.70);

                        @media(max-width: $md6+px) {
                            gap: 12px;
                        }
                    }
                }
            }
        }
    }

    .subcardsWrap {
        margin-top: 80px;

        .title {
            text-align: center;
        }

        .subcards {
            margin-top: 60px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(1, 1fr);
            gap: 20px;

            @media(max-width: $md4+px) {
                display: flex;
                flex-direction: column;
            }

            @media(max-width: $md6+px) {
                margin-top: 40px;
                gap: 20px;
            }

            .subcard {
                border-radius: 10px;
                border: 1px solid #262626;
                background: #191919;
                padding: 20px 20px 30px;

                @media(max-width: $md6+px) {
                    padding: 20px 18px 30px;
                }

                &Num {
                    color: #fff;
                }

                &Title {
                    color: #fff;
                    margin-top: 40px;

                    @media(max-width: $md6+px) {
                        margin-top: 20px;
                    }
                }

                &Text {
                    margin-top: 14px;
                }
            }
        }
    }
}