@import '../../../styles/var';

.wrap {
    position: relative;
    padding-top: 180px;

    @media(max-width: $md1+px) {
        padding-top: 160px;
    }

    @media(max-width: $md3+px) {
        padding-top: 140px;
    }

    @media (max-width: $md6+px) {
        padding-top: 120px;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {

        }

        .subtitle {
            color: #FFF;
            max-width: 575px;
            margin: 20px 0 40px;

            @media(max-width: $md3+px) {
                max-width: 520px;
            }

            @media(max-width: $md4+px) {
                max-width: 100%;
            }

            @media(max-width: $md6+px) {
                margin: 12px 0 20px;
                font-size: 16px;
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            gap: 20px;
            max-width: 585px;

            @media(max-width: $md3+px) {
                max-width: 500px;
            }

            @media(max-width: $md4+px) {
                max-width: 100%;
            }

            @media(max-width: $md6+px) {
                gap: 15px;
                font-size: 14px;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        bottom: -20px;
        right: 0;
        width: 539px;

        @media(max-width: $md3+px) {
            width: 450px;
            bottom: 0;
        }

        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            margin: 50px auto 0;
            max-width: 400px;
            width: 100%;
        }

        @media(max-width: $md6+px) {
            margin-top: 40px;
        }
    }
}