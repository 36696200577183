@import '../../../styles/var';

.wrap {

    .title {
        max-width: 520px;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        gap: 20px;
        margin-top: 40px;

        @media(max-width: 900px) {
            display: flex;
            flex-direction: column;
        }

        @media(max-width: $md6+px) {
            margin-top: 40px;
        }

        .card {
            position: relative;
            overflow: hidden;
            min-height: 320px;
            padding: 40px 50px;
            border-radius: 10px;
            border: 1px solid #262626;
            background: #191919;

            @media(max-width: $md4+px) {
                padding: 40px 30px;
            }

            @media(max-width: 900px) {
                padding: 40px 60px;
                min-height: 270px;
            }

            @media(max-width: $md6+px) {
                padding: 40px 18px 40px 40px;
                min-height: 250px;
            }

            &Title {
                position: relative;
                z-index: 5;

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: -30px;
                    transform: translateY(-50%);
                    width: 14px;
                    height: 14px;
                    background-image: url('../../../assets/img/list_check.svg');
                    background-repeat: no-repeat;
                    background-position: center left;
                    background-size: cover;

                    @media(max-width: $md4+px) {
                        left: -20px;
                    }

                    @media(max-width: 900px) {
                        left: -30px;
                    }

                    @media(max-width: $md6+px) {
                        width: 12px;
                        height: 12px;
                    }
                }
            }

            &Text {
                position: relative;
                z-index: 5;
                margin-top: 14px;
                color: rgba(255, 255, 255, 0.70);
            }

            &Image {
                position: absolute;
                width: 240px;
                z-index: 2;
                right: 20px;
                bottom: 0;

                @media(max-width: $md3+px) {
                    width: 170px;
                }

                @media(max-width: $md6+px) {
                    width: 150px;
                }
            }
        }
    }
}