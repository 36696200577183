@import './var';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.font-64-banner {
    display: inline-flex;
    font-family: $manrope;
    font-size: 64px;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
    background: linear-gradient(90deg, #9869DA 0%, #F8AD79 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media(max-width: $md4+px) {
        font-size: 50px;
    }

    @media(max-width: $md6+px) {
        font-size: 40px;
    }
}

.font-64 {
    color: #FFF;
    font-family: $manrope;
    font-size: 64px;
    font-weight: 400;
    line-height: 120%;

    @media(max-width: $md4+px) {
        font-size: 48px;
    }

    @media(max-width: $md6+px) {
        font-size: 36px;
    }
}

.font-48 {
    color: #FFF;
    font-family: $manrope;
    font-size: 48px;
    font-weight: 400;
    line-height: 120%;

    @media(max-width: $md4+px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 32px;
    }
}

.font-36 {
    color: #FFF;
    font-family: $manrope;
    font-size: 36px;
    font-weight: 400;
    line-height: 120%;

    @media(max-width: $md4+px) {
        font-size: 32px;
    }

    @media(max-width: $md6+px) {
        font-size: 24px;
    }
}

.font-36-20 {
    color: #FFF;
    font-family: $manrope;
    font-size: 36px;
    font-weight: 500;
    line-height: 120%;

    @media(max-width: $md4+px) {
        font-size: 28px;
    }

    @media(max-width: $md6+px) {
        font-size: 20px;
    }
}

.font-32 {
    color: #FFF;
    font-family: $manrope;
    font-size: 32px;
    font-weight: 500;
    line-height: 120%;

    @media(max-width: $md4+px) {
        font-size: 28px;
    }

    @media(max-width: $md6+px) {
        font-size: 24px;
    }
}

.font-24 {
    color: rgba(255, 255, 255, 0.70);
    font-family: $roboto;
    font-size: 24px;
    font-weight: 300;
    line-height: 140%;

    @media(max-width: $md4+px) {
        font-size: 20px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-20 {
    color: rgba(255, 255, 255, 0.70);
    font-family: $roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;

    @media(max-width: $md4+px) {
        font-size: 18px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-16 {
    font-family: $roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}