@import '../../../styles/var';

.container {
    position: relative;
}

.wrap {
    position: relative;
    padding-top: 180px;
    display: grid;
    grid-template-columns: 1fr 400px;
    gap: 20px;

    @media(max-width: $md1+px) {
        padding-top: 160px;
    }

    @media(max-width: $md3+px) {
        padding-top: 140px;
        grid-template-columns: calc(65% - 10px) calc(35% - 10px);
    }

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
        gap: 140px;
    }

    @media (max-width: $md6+px) {
        padding-top: 120px;
        gap: 120px;
    }

    .formWrap {
        border-radius: 10px;
        background: linear-gradient(90deg, #9869DA 0%, #F8AD79 100%);
        padding: 60px 50px 40px;

        @media(max-width: $md3+px) {
            padding: 60px 40px 40px;
        }

        @media(max-width: $md3+px) {
            padding: 60px 30px 40px;
        }

        @media(max-width: $md6+px) {
            padding: 40px 18px 30px;
        }

        .title {
            max-width: 520px;
            text-align: center;
            margin: 0 auto;
            color: #000;
            font-weight: 500;
        }

        .form {
            .inputs {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(3, 1fr);
                gap: 12px 20px;
                margin: 40px 0;

                @media(max-width: 700px) {
                    display: flex;
                    flex-direction: column;
                }

                @media(max-width: $md6+px) {
                    margin-top: 35px;
                    gap: 8px;
                }

                .group {
                    height: 100%;

                    &:nth-child(1) {
                        grid-column: 1/1;
                        grid-row: 1/1;
                    }

                    &:nth-child(2) {
                        grid-column: 2/2;
                        grid-row: 1/1;
                    }

                    &:nth-child(3) {
                        grid-column: 1/1;
                        grid-row: 2/2;
                    }

                    &:nth-child(4) {
                        grid-column: 1/1;
                        grid-row: 3/3;
                    }

                    &:nth-child(5) {
                        grid-column: 2/2;
                        grid-row: 2/4;
                    }
                }
            }

            .submit {
                display: flex;
                justify-content: center;
            }
        }
    }

    .contacts {
        border-radius: 10px;
        border: 1px solid #262626;
        background: #191919;
        padding: 60px 20px 40px;

        @media(max-width: $md6+px) {
            padding: 40px 18px 30px;
        }

        .items {
            display: flex;
            flex-direction: column;
            gap: 14px;

            .item {
                color: #FFF;

                &Title {
                    font-weight: 500;
                    position: relative;
                    padding-left: 26px;

                    @media(max-width: $md6+px) {
                        padding-left: 22px;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 14px;
                        height: 14px;
                        background-image: url('../../../assets/img/list_check.svg');
                        background-repeat: no-repeat;
                        background-position: center top;
                        background-size: cover;

                        @media(max-width: $md6+px) {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                &Text {
                    margin-top: 4px;
                    padding-left: 26px;

                    @media(max-width: $md6+px) {
                        padding-left: 22px;
                    }
                }

                a {
                    text-decoration: none;
                    color: inherit;

                    &:hover {
                        color: rgba($color: #fff, $alpha: 0.7)
                    }
                }
            }
        }

        .text {
            margin-top: 45px;
            font-weight: 300;

            @media(max-width: $md6+px) {
                margin-top: 30px;
            }
        }
    }
}