@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 517px;
    gap: 80px;
    padding-top: 180px;

    @media(max-width: $md1+px) {
        padding-top: 160px;
    }

    @media(max-width: $md3+px) {
        padding-top: 140px;
        grid-template-columns: 1fr 420px;
    }

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        gap: 140px;
    }

    @media (max-width: $md6+px) {
        gap: 120px;
        padding-top: 120px;
    }

    .content {

        .title {

            span {
                font-family: $manrope;
                font-size: 64px;
                font-weight: 600;
                line-height: 120%;
                text-transform: uppercase;
                background: linear-gradient(90deg, #9869DA 0%, #F8AD79 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media(max-width: $md4+px) {
                    font-size: 50px;
                }

                @media(max-width: $md6+px) {
                    font-size: 40px;
                }
            }
        }

        .text {
            margin-top: 40px;
        }

        .btn {}
    }

    .list {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 12px;

        @media(max-width: $md4+px) {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
        }

        .item {
            border-radius: 400px;
            border: 1px solid #262626;
            background: #191919;
            display: grid;
            grid-template-columns: 126px 1fr;
            align-items: center;
            gap: 36px;
            padding: 10px 40px 10px 10px;

            @media(max-width: $md3+px) {
                padding: 10px 20px 10px 10px;
            }

            @media(max-width: $md4+px) {
                padding: 14px 30px 14px 14px;
                grid-template-columns: 100px 1fr;
            }

            @media(max-width: $md6+px) {
                grid-template-columns: 90px 1fr;
                gap: 14px;
                padding: 14px 20px 14px 14px;
            }

            .num {
                width: 126px;
                height: 126px;
                background-image: url('../../../assets/img/PartnershipPage/banner_circle.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: #000;
                font-weight: 500;

                @media(max-width: $md4+px) {
                    width: 100px;
                    height: 100px;
                }

                @media(max-width: $md6+px) {
                    width: 90px;
                    height: 90px;
                }
            }

            &Text {
                color: #FFF;
            }
        }
    }
}