@import '../../../styles/var';

.wrap {
    position: relative;
    overflow: hidden;
    color: #000;
    border-radius: 10px;
    background: linear-gradient(90deg, #9869DA 0%, #F8AD79 100%);
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: flex-end;
    justify-content: space-between;
    gap: 40px;
    padding: 40px 100px 50px;

    @media(max-width: $md3+px) {
        padding: 40px 50px 50px;
    }

    @media(max-width: $md5+px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: unset;
        justify-content: unset;
        padding: 40px 30px 120px;
    }

    @media(max-width: $md6+px) {
        padding: 40px 18px 120px;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {
            color: #000;
        }

        .subtitle {
            color: #000;
            margin-top: 4px;

            @media(max-width: $md5+px) {
                margin-top: 8px;
            }
        }
    }

    .btn {
        position: relative;
        z-index: 5;
    }

    .image {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 0;
        width: 495px;

        @media(max-width: $md3+px) {
            width: 400px;
            opacity: 0.7;
        }

        @media(max-width: $md6+px) {
            width: 300px;
            opacity: 1;
        }
    }
}