@import '../../../styles/var';

.wrap {
    position: relative;

    .title {}

    .block {

        @media (max-width: $md4+px) {
            display: flex !important;
            flex-direction: column;
            justify-content: unset !important;
            gap: 50px !important;
        }

        @media (max-width: $md6+px) {
            gap: 40px;
        }

        .list {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 14px;

            .item {
                position: relative;
                padding-left: 26px;

                @media(max-width: $md6+px) {
                    padding-left: 22px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 14px;
                    height: 14px;
                    background-image: url('../../../assets/img/list_check.svg');
                    background-repeat: no-repeat;
                    background-position: center top;
                    background-size: cover;

                    @media(max-width: $md6+px) {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }

        &_01 {
            margin-top: 60px;
            display: grid;
            grid-template-columns: 600px auto;
            justify-content: space-between;
            align-items: center;
            gap: 40px;

            @media (max-width: $md3+px) {
                grid-template-columns: 1fr auto;
            }

            @media (max-width: $md6+px) {
                margin-top: 40px;
            }

            .text_01 {
                margin: 20px 0 40px 0;

                @media (max-width: $md6+px) {
                    margin: 20px 0 30px 0;
                }
            }

            .image {
                width: 515px;

                @media (max-width: $md3+px) {
                    width: 100%;
                    max-width: 380px;
                }

                @media (max-width: $md6+px) {
                    max-width: 260px;
                }

                img {
                    width: 100%;
                }
            }
        }

        &_02 {
            margin-top: 110px;
            display: grid;
            grid-template-columns: auto 600px;
            justify-content: space-between;
            align-items: center;
            gap: 40px;

            @media (max-width: $md3+px) {
                grid-template-columns: auto 1fr;
            }

            @media (max-width: $md4+px) {
                flex-direction: column-reverse;
                margin-top: 80px;
            }

            .text {
                margin-top: 40px;
                display: flex;
                flex-direction: column;
                gap: 20px;

                @media (max-width: $md6+px) {
                    gap: 15px;
                    margin-top: 20px;
                }
            }

            .image {
                width: 505px;

                @media (max-width: $md3+px) {
                    width: 100%;
                    max-width: 380px;
                }

                @media (max-width: $md6+px) {
                    max-width: 260px;
                }

                img {
                    width: 100%;
                }
            }
        }
    }
}