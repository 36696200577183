@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 562px;
    align-items: flex-end;

    @media(max-width: $md3+px) {
        grid-template-columns: 1fr 420px;
        gap: 10px;
    }

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        align-items: unset;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {
            margin-bottom: 20px;

            span {
                background: linear-gradient(90deg, #9A6BD8 29.91%, #F6AB7C 99.99%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .text_01,
        .text_02 {
            max-width: 521px;

            @media(max-width: $md4+px) {
                max-width: 100%;
            }
        }

        .subtitle {
            margin: 80px 0 20px;

            @media(max-width: $md4+px) {
                margin: 60px 0 20px;
            }

            @media(max-width: $md6+px) {
                margin-top: 40px;
            }
        }
    }

    .image {
        position: relative;
        z-index: 2;

        @media(max-width: $md4+px) {
            width: 100%;
            max-width: 400px;
            display: flex;
            margin: 50px auto 0;
        }

        @media(max-width: $md6+px) {
            margin-top: 40px;
        }

        img {
            width: 100%;
        }
    }
}